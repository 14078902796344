import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import Features from "../components/Features"
import Navbar from "../components/Navbar"

import logo from "../img/logo.svg"
import adelaide from "../img/adelaide.svg"
import down from "../img/down-arrow.svg"

import floorplatesIcon from "../img/icons/icon-floorplate.svg"
import levelsIcon from "../img/icons/icon-levels.svg"
import performanceIcon from "../img/icons/icon-performance.svg"
import lightIcon from "../img/icons/icon-light.svg"
import ratingIcon from "../img/icons/icon-rating.svg"
import internetIcon from "../img/icons/icon-internet.svg"

import trainIcon from "../img/icons/icon-train.svg"
import foodIcon from "../img/icons/icon-food.svg"
import transportIcon from "../img/icons/icon-transport.svg"
import parklandsIcon from "../img/icons/icon-parklands.svg"
import tripIcon from "../img/icons/icon-trip.svg"
import showerIcon from "../img/icons/icon-shower.svg"

import healthIcon from "../img/icons/icon-health.svg"
import hospitalityIcon from "../img/icons/icon-hospitality.svg"

import retailIcon from "../img/icons/icon-retail.svg"

import mapImage from "../img/map.jpg"
import floorplateImage from "../img/floorplate.png"

import floorplatesIconReversed from "../img/icons/icon-floorplate-reversed.svg"
import levelsIconReversed from "../img/icons/icon-levels-reversed.svg"
import lightIconReversed from "../img/icons/icon-light-reversed.svg"
import performanceIconReversed from "../img/icons/icon-performance-reversed.svg"
import ratingIconReversed from "../img/icons/icon-rating-reversed.svg"

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  intro,
  workplaceImage1,
  workplaceImage2,
  workplaceImage3,
  workplaceImage4,
  workplaceImage5,
  workplaceImage6,
  location
}) => (
  <div>
    <section
      className="hero is-fullheight intro-bg"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        // backgroundAttachment: `fixed`,
        backgroundSize: `cover`,
        backgroundPosition: `50% 50%`,
        position: `relative`
      }}
    >
      <div
        className={`hero-head ${
          location && location.pathname === "/" ? "is-home" : "is-page"
        }`}
      >
        <Navbar revert />
      </div>

      <div
        className="hero-body pt-0"
        style={{
          zIndex: 10
        }}
      >
        <div className="container has-text-centered">
          <h1 className="title has-text-white">
            <img
              className="has-shadow"
              src={logo}
              alt="400 KW"
              style={{ width: "250px" }}
            />
          </h1>
          <h2 className="subtitle has-text-white">
            <img
              className="has-shadow"
              src={adelaide}
              alt="Adelaide"
              style={{ width: "300px" }}
            />
          </h2>
        </div>

        <div className="navigate-down">
          <a href="#highlights">
            <img className="has-shadow" src={down} alt="Navigate down" />
          </a>
        </div>
      </div>
    </section>

    <section id="highlights" className="section pt-6 pb-6">
      <div className="container">
        <div className="columns is-centered is-multiline ml-0 mr-0">
          <div className="column is-10">
            <h1 className="title has-text-centered section-title">
              READY FOR BUSINESS
            </h1>

            <div className="mb-4 has-text-centered">
              400 King William Street is an outstanding A Grade office tower
              located at the southern end of Adelaide’s prime boulevard, King
              William Street.
            </div>
            <div className="mb-4 has-text-centered">
              Totalling some 21,616 sqm over 10 levels the building boasts one
              of the biggest floor plates in the CBD of 2,036 sqm approximately.
              The building has a modern, clean lined streetscape appearance with
              a full glass wall facade that maximises the natural light from
              three sides.
            </div>

            <div className="mb-5 has-text-centered">
              The building was also designed to ensure maximum energy
              performance, recently achieving a 5.5 Star NABERS rating.
            </div>
          </div>

          <div className="columns is-centered is-mobile pb-6">
            <div className="column has-text-centered is-info">
              <div className="is-info-icon is-circular">
                <img src={floorplatesIcon} alt="Floor" />
              </div>

              <h2>2,036 SQM FLOOR PLATES</h2>
            </div>

            <div className="column has-text-centered is-info">
              <div className="is-info-icon is-circular">
                <img src={levelsIcon} alt="Levels" />
              </div>

              <h2>21,616 SQM OVER 10 LEVELS</h2>
            </div>

            <div className="column has-text-centered is-info">
              <div className="is-info-icon is-circular">
                <img src={performanceIcon} alt="Connectivity" />
              </div>

              <h2>Excellent connectivity</h2>
            </div>

            <div className="column has-text-centered is-info">
              <div className="is-info-icon is-circular">
                <img src={lightIcon} alt="Light" />
              </div>

              <h2>MAXIMISE NATURAL LIGHT</h2>
            </div>

            <div className="column has-text-centered is-info">
              <div className="is-info-icon is-circular">
                <img src={ratingIcon} alt="NABERS" />
              </div>

              <h2>5 .5 STAR NABERS RATING.</h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div data-scroll data-scroll-speed="0.5" className="columns ml-0 mr-0">
      <div
        className="column p-0"
        style={{
          minHeight: `240px`,
          backgroundImage: `url(${
            !!workplaceImage1.image.childImageSharp
              ? workplaceImage1.image.childImageSharp.fluid.src
              : workplaceImage1.image
          })`,

          backgroundSize: `cover`,
          backgroundPosition: `50% 50%`
        }}
      ></div>

      <div
        className="column p-0"
        style={{
          minHeight: `240px`,
          backgroundImage: `url(${
            !!workplaceImage2.image.childImageSharp
              ? workplaceImage2.image.childImageSharp.fluid.src
              : workplaceImage2.image
          })`,

          backgroundSize: `cover`,
          backgroundPosition: `50% 50%`
        }}
      ></div>

      <div
        className="column p-0"
        style={{
          minHeight: `240px`,
          backgroundImage: `url(${
            !!workplaceImage3.image.childImageSharp
              ? workplaceImage3.image.childImageSharp.fluid.src
              : workplaceImage3.image
          })`,

          backgroundSize: `cover`,
          backgroundPosition: `50% 50%`
        }}
      ></div>
    </div>

    <section id="precinct" className="section pt-6 pb-6">
      <div className="container">
        <div className="columns is-centered is-multiline">
          <div className="column is-10">
            <h1 className="title has-text-centered section-title">
              Address To Impress
            </h1>

            <div className="mb-4 has-text-centered">
              Proximity to public transport services and daily amenities
              including postal services, financial services, childcare, gym and
              health care services and food and beverage options.
            </div>
            <br />
          </div>

          <div className="columns ml-0 mr-0 pb-6">
            <div className="column has-text-centered is-info">
              <div className="is-info-icon is-circular">
                <img src={trainIcon} alt="Tram" />
              </div>

              <h2>Close proximity to the Tram on King William Street</h2>
            </div>

            <div className="column has-text-centered is-info">
              <div className="is-info-icon is-circular">
                <img src={foodIcon} alt="Food" />
              </div>

              <h2>
                Great food and beverage amenities in the immediate vicinity
              </h2>
            </div>

            <div className="column has-text-centered is-info">
              <div className="is-info-icon is-circular">
                <img src={internetIcon} alt="Internet" />
              </div>

              <h2>
                The fastest and most cost-efficient internet connection in the
                world via Ten Gigabit Adelaide
              </h2>
            </div>

            <div className="column has-text-centered is-info">
              <div className="is-info-icon is-circular">
                <img src={parklandsIcon} alt="Parklands" />
              </div>

              <h2>Parklands and outdoor facilities right on the doorstep</h2>
            </div>

            <div className="column has-text-centered is-info">
              <div className="is-info-icon is-circular">
                <img src={showerIcon} alt="Showers" />
              </div>

              <h2>
                Basement End of Trip Facilities plus in-building private gym.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className="columns ml-0 mr-0">
      <div
        className="column p-0"
        style={{
          minHeight: `240px`,
          backgroundImage: `url(${
            !!workplaceImage4.image.childImageSharp
              ? workplaceImage4.image.childImageSharp.fluid.src
              : workplaceImage4.image
          })`,

          backgroundSize: `cover`,
          backgroundPosition: `50% 50%`
        }}
      ></div>

      <div
        className="column p-0"
        style={{
          minHeight: `240px`,
          backgroundImage: `url(${
            !!workplaceImage5.image.childImageSharp
              ? workplaceImage5.image.childImageSharp.fluid.src
              : workplaceImage5.image
          })`,

          backgroundSize: `cover`,
          backgroundPosition: `50% 50%`
        }}
      ></div>

      <div
        className="column p-0"
        style={{
          minHeight: `240px`,
          backgroundImage: `url(${
            !!workplaceImage6.image.childImageSharp
              ? workplaceImage6.image.childImageSharp.fluid.src
              : workplaceImage6.image
          })`,

          backgroundSize: `cover`,
          backgroundPosition: `50% 50%`
        }}
      ></div>
    </div>

    <section id="workplace" className="section pt-6 pb-6">
      <div className="container">
        <div className="columns is-centered is-multiline">
          <div className="column is-10">
            <h1 className="title has-text-centered section-title">
              WELL PLACED FOR WORK
            </h1>

            <h2 className="subtitle has-text-centered">
              From 400 King William Street it’s a short walk to the Adelaide Law
              Courts, Victoria Square and the Adelaide Central Markets. Directly
              opposite, Adelaide’s tram service which can take you to the
              Bio-Science Precinct and Hindmarsh.
            </h2>

            {/* <div className="columns ml-0 mr-0">
              <div className="column has-text-centered is-info">
                <div className="is-info-icon is-circular">
                  <img src={trainIcon} alt="Train" />
                </div>

                <h2>Close proximity to the Tram on King William Street</h2>
              </div>

              <div className="column has-text-centered is-info">
                <div className="is-info-icon is-circular">
                  <img src={foodIcon} alt="Food" />
                </div>

                <h2>
                  Great food and beverage amenities in the immediate vicinity
                </h2>
              </div>

              <div className="column has-text-centered is-info">
                <div className="is-info-icon is-circular">
                  <img src={internetIcon} alt="Internet" />
                </div>

                <h2>
                  The fastest and most costefficient internet connection in the
                  world via Ten Gigabit Adelaide
                </h2>
              </div>

              <div className="column has-text-centered is-info">
                <div className="is-info-icon is-circular">
                  <img src={parklandsIcon} alt="Parklands" />
                </div>

                <h2>Parklands and outdoor fascilities right on the doorstep</h2>
              </div>

              <div className="column has-text-centered is-info">
                <div className="is-info-icon is-circular">
                  <img src={showerIcon} alt="Shower" />
                </div>

                <h2>
                  End of Trip facilities as well as the in-building private gym
                  designed by snap fitness cater to many.
                </h2>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>

    <div className="columns p-0 m-0">
      <div
        className="column has-text-centered is-9"

        // style={{
        //   minHeight: `620px`,
        //   backgroundImage: `url(${
        //     !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        //   })`,

        //   backgroundSize: `cover`,
        //   backgroundPosition: `50% 50%`
        // }}
      >
        <img className="image" src={mapImage} alt="Map" />
      </div>

      <div className="column is-3">
        <div className="content">
          <div className="work-box">
            <div className="is-info-icon is-circular mr-3">
              <img src={healthIcon} alt="Health" />
            </div>
            <div>
              <h2>HEALTH AND WELLNESS</h2>
              <ul>
                <li>01 EFM health club</li>
                <li>02 Veale Gardens</li>
                <li>03 Tennis Courts</li>
                <li>04 Tree Climb Zip Lining</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="work-box">
            <div className="is-info-icon is-circular mr-3">
              <img src={hospitalityIcon} alt="Hospitality" />
            </div>
            <div>
              <h2>HOSPITALITY</h2>
              <ul>
                <li>05 Funk café</li>
                <li>06 Mimasu</li>
                <li>07 L Law Bistro</li>
                <li>08 Fluer and Brew</li>
                <li>09 Gilbert Street Hotel</li>
                <li>10 Prohibition</li>
                <li>11 Ba-Bar Lounge</li>
                <li>12 Astonish Patisserie</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="work-box">
            <div className="is-info-icon is-circular mr-3">
              <img src={transportIcon} alt="Transport" />
            </div>
            <div>
              <h2>TRANSPORT</h2>
              <ul>
                <li>13 Tram Stop</li>
                <li>14 Bus Stop</li>
                <li>15 Free City Bikes</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="content mb-3">
          <div className="work-box">
            <div className="is-info-icon is-circular mr-3">
              <img src={retailIcon} alt="Retail" />
            </div>
            <div>
              <h2>RETAIL AND LANDMARKS</h2>
              <ul>
                <li>16 IGA & BWS</li>
                <li>17 Adelaide Central Markets</li>
                <li>18 Victoria Square</li>
                <li>19 Adelaide Town Hall</li>
                <li>20 Adelaide CBD</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="floorplate" className="columns pt-6 pb-6 ml-0 mr-0 bg-gold ">
      <div className="container">
        <div className="columns is-centered is-multiline">
          <div className="column is-10">
            <h1 className="title has-text-centered section-title has-text-white">
              Floorplate
            </h1>
            <h2 className="subtitle has-text-centered has-text-white">
              The open plan layout has the potential to accommodate a
              traditional or modern agile workplace.
            </h2>
            <h2 className="subtitle has-text-centered has-text-white">
              Each level is approximately 2036 m2 and the arrangement of the
              floor lends itself to be split into multiple sized tenancies.
            </h2>
            <div className="section pt-0 pb-0">
              <img alt="Floorplate" className="image" src={floorplateImage} />
            </div>
          </div>

          <div className="columns mt-4 ml-0 mr-0">
            <div className="column has-text-centered is-info is-info--reversed">
              <div className="is-info-icon is-circular is-info-icon--reversed">
                <img src={floorplatesIconReversed} alt="SQM" />
              </div>

              <h2>2036 SQM FLOOR PLATES</h2>
            </div>

            <div className="column has-text-centered is-info is-info--reversed">
              <div className="is-info-icon is-circular is-info-icon--reversed">
                <img src={performanceIconReversed} alt="Performance" />
              </div>

              <h2>MAXIMUM ENERGY PERFORMANCE</h2>
            </div>

            <div className="column has-text-centered is-info is-info--reversed">
              <div className="is-info-icon is-circular is-info-icon--reversed">
                <img src={ratingIconReversed} alt="Rating" />
              </div>

              <h2>5 .5 STAR NABERS RATING</h2>
            </div>

            <div className="column has-text-centered is-info is-info--reversed">
              <div className="is-info-icon is-circular is-info-icon--reversed">
                <img src={levelsIconReversed} alt="Floorplate" />
              </div>

              <h2>GENERAL FLOORPLATE</h2>
            </div>

            <div className="column has-text-centered is-info is-info--reversed">
              <div className="is-info-icon is-circular is-info-icon--reversed">
                <img src={lightIconReversed} alt="Sun" />
              </div>

              <h2>MAXIMISE NATURAL LIGHT</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,

  intro: PropTypes.shape({
    blurbs: PropTypes.array
  })
}

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout location={location}>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        intro={frontmatter.intro}
        workplaceImage1={frontmatter.workplace.workplaceImage1}
        workplaceImage2={frontmatter.workplace.workplaceImage2}
        workplaceImage3={frontmatter.workplace.workplaceImage3}
        workplaceImage4={frontmatter.workplace.workplaceImage4}
        workplaceImage5={frontmatter.workplace.workplaceImage5}
        workplaceImage6={frontmatter.workplace.workplaceImage6}
        location={location}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        workplace {
          workplaceImage1 {
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          workplaceImage2 {
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          workplaceImage3 {
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          workplaceImage4 {
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          workplaceImage5 {
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          workplaceImage6 {
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

// vacancies {
//   title
//   foorarea
//   image {
//     childImageSharp {
//       fluid(maxWidth: 480, quality: 80) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// }
