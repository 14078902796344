import React from "react"
import { Link } from "gatsby"

import logo from "../img/logo.svg"
import cbreLogo from "../img/icons/logo-cbre.svg"
import realisLogo from "../img/icons/logo-realis.svg"

import pulseLogo from "../img/logo-pulse-white.svg"

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter pb-6">
        <div className="content has-text-white-ter">
          <div className="container has-text-white-ter">
            <div className="section">
              <div className="columns">
                <div className="column is-one-third">
                  <span className="is-gold">Exclusive agents</span>
                  <img className="image mt-4 mb-4" src={cbreLogo} alt="CBRE" />
                  <span className="is-gold">Contact</span>
                  <div className="content">
                    <strong className="has-text-white-ter">
                      Michael Pfitzner
                    </strong>
                    <br />
                    Phone 0408 807 838
                    <br />
                    m.pfitzner@cbre.com.au
                  </div>
                </div>
                <div className="column is-one-third">
                  <span className="is-gold">Proudly Owned by</span>
                  <img
                    className="image mt-4 mb-4"
                    src={realisLogo}
                    alt="Real I.S."
                  />
                </div>
                <div className="column is-one-third">
                  <span className="is-gold">Pulse gym registration</span>
                  <a href="https://pulse.400kingwilliamstreet.com.au/">
                    <img
                      className="image mt-4 mb-4"
                      style={{ width: `150px` }}
                      src={pulseLogo}
                      alt="Pulse"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
