import React from "react"
import { Link } from "gatsby"
import logoReverse from "../img/logo-reverse.svg"

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: ""
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            })
      }
    )
  }

  render() {
    return !this.props.isHome ? (
      <nav
        className={`navbar is-transparent ${this.state.navBarActiveClass}`}
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            {!this.props.revert && (
              <Link to="/" className="navbar-item" title="Logo">
                <img src={logoReverse} alt="40 MBC" style={{ width: "88px" }} />
              </Link>
            )}

            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div
              className={`navbar-end has-text-centered ${
                this.props.revert ? "is-reverted" : ""
              }`}
            >
              <Link className="navbar-item" to="/#highlights">
                Highlights
              </Link>
              <Link className="navbar-item" to="/#precinct">
                Precinct
              </Link>
              <Link className="navbar-item" to="/#workplace">
                Workplace
              </Link>
              <Link className="navbar-item" to="/#vacancies">
                Vacancies
              </Link>

              <Link className="navbar-item" to="/amenities">
                Amenities
              </Link>
            </div>
          </div>
        </div>
      </nav>
    ) : (
      <span></span>
    )
  }
}

export default Navbar
