import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"

import gymIcon from "../img/icons/icon-gym.svg"
import healthIcon from "../img/icons/icon-health.svg"
import showerIcon from "../img/icons/icon-shower.svg"
import bikerackIcon from "../img/icons/icon-bikerack.svg"

import pulseLogo from "../img/logo-pulse.svg"

export const DetailPageTemplate = ({
  image,
  title,
  heading,
  description,
  fullImage,
  main
}) => (
  <div className="detail-page">
    <div
      className="full-width-image-container margin-top-0 intro-bg"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundSize: `cover`,
        backgroundPosition: `50% 50%`,
        position: `relative`
      }}
    >
      <h1
        data-scroll
        data-scroll-speed="1"
        className="has-text-weight-bold is-size-1 "
        style={{
          color: "white",
          padding: "1rem",
          zIndex: 10
        }}
      >
        {title}
      </h1>
    </div>
    <section className="section pt-0">
      <div className="container">
        <div className="columns is-centered mb-6">
          <div className="column is-8">
            <p className="content">{description}</p>
            <p className="content">
              This area also includes a repair room with a universal work stand,
              standard bike tools and an electric bicycle pump. In addition, the
              facilities offer a wellness centre designed by Fauna Yoga
              providing yoga, pilates and meditation rooms for tenants to book
              individually via an in-house booking system.
            </p>
            <p className="content">
              Last but not least, the facilities also include a well-equipped
              gym designed by Snap Fitness Adelaide.
            </p>
          </div>
        </div>
        <div className="columns is-centered mb-6">
          <div className="column has-text-centered ">
            <p>Click here for gym registration:</p>
            <a href="https://pulse.400kingwilliamstreet.com.au/">
              <img style={{ maxWidth: `150px` }} src={pulseLogo} alt="Floor" />
            </a>
          </div>
        </div>
        <div className="columns mb-6">
          <div className="column has-text-centered is-info">
            <div className="is-info-icon is-circular">
              <img src={healthIcon} alt="Floor" />
            </div>

            <h2>
              wellness centre designed by Fauna Yoga providing yoga, pilates and
              meditation
            </h2>
          </div>

          <div className="column has-text-centered is-info">
            <div className="is-info-icon is-circular">
              <img src={showerIcon} alt="Floor" />
            </div>

            <h2>350 Lockers, 20 showers and Ironing station</h2>
          </div>

          <div className="column has-text-centered is-info">
            <div className="is-info-icon is-circular">
              <img src={bikerackIcon} alt="Floor" />
            </div>

            <h2>
              Bike racks for parking 80 bicycles. repair room with a universal
              work stand + Electric Bike pump.
            </h2>
          </div>

          <div className="column has-text-centered is-info">
            <div className="is-info-icon is-circular">
              <img src={gymIcon} alt="Floor" />
            </div>

            <h2>well-equipped gym designed by Snap Fitness Adelaide</h2>
          </div>
        </div>
      </div>

      <div className="tile is-ancestor">
        <div className="tile is-vertical">
          <div className="tile">
            <div className="tile is-parent is-vertical">
              <article className="tile is-child">
                <PreviewCompatibleImage imageInfo={main.image1} />
              </article>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child">
                <PreviewCompatibleImage imageInfo={main.image2} />
              </article>
            </div>
          </div>
          <div className="tile is-parent">
            <article className="tile is-child">
              <PreviewCompatibleImage imageInfo={main.image3} />
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
)

DetailPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  main: PropTypes.shape({
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  })
}

const DetailPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout location={location}>
      <DetailPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        fullImage={frontmatter.full_image}
        main={frontmatter.main}
      />
    </Layout>
  )
}

DetailPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default DetailPage

export const detailPageQuery = graphql`
  query DetailPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        main {
          image1 {
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image2 {
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image3 {
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        full_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
